import React from "react";
import { graphql } from 'gatsby';
import Nav from '../components/Nav'
import Hero from '../components/Hero';
import CategoryCard from '../components/CategoryCard';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import SEO from '../components/seo/SEO';

const Layout = ({ data }) => {
    const { edges } = data.allMarkdownRemark;
    return (
        <>
            <SEO />
            <div className="gradient">
                <Nav />
                <Hero
                    heading={data.site.siteMetadata.main}
                    subText={data.site.siteMetadata.description}
                    subHeading={data.site.siteMetadata.subMain}
                    buttonText="Consulter les cours"
                    image={data.homeImage.childImageSharp.fluid}
                    handleClick={() => {
                        const element = document.getElementById('courses');
                        if (element) element.scrollIntoView();
                    }}
                />
                
            </div>

            <h1 id="courses" className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">Cours disponibles</h1>
            <div className="w-full mb-4">	
                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
            </div>

            <div className="container flex px-3 mx-auto flex flex-wrap">
                {
                    edges.map(edge => {
                        const { frontmatter } = edge.node;
                        return <CategoryCard key={frontmatter.path} frontmatter={frontmatter}/>
                    })
                }
            </div>

            <Contact />
            <Footer image={data.footerImage.childImageSharp.fluid}/>
        </>
    )
}

export const query = graphql`
    query HomepageQuery {
        allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "category"}, published: { eq: true } } }
        sort: {order: DESC, fields: [frontmatter___date]}
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        path
                        date
                        excerpt
                        usingTags
                        image {
                            publicURL
                            childImageSharp {
                                sizes(maxWidth: 1240 ) {
                                    srcSetWebp
                                }
                            }
                        }
                    }
                }
            }
        },
        site {
            siteMetadata {
                description,
                main,
                subMain
            }
        },
        homeImage: file(relativePath: {eq: "hero.png"}) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        },
        footerImage: file(relativePath: {eq: "Logo.png"}) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

export default Layout;
